<template>
  <el-card class="box-card full-screen cash-account-recharge-apply">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('checkStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('checkPending')" value="2"></el-option>
            <el-option :label="$t('getApproved')" value="3"></el-option>
            <el-option :label="$t('notApproved')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column fixed="left" :label="$t('applyOrg')" width="160">
          <template slot-scope="scope">{{scope.row.orgName}}({{scope.row.orgInfoCode}})</template>
        </el-table-column>
        <!-- <el-table-column :label="$t('creator')" width="160">
          <template slot-scope="scope">{{scope.row.name}} {{scope.row.surname}} ({{scope.row.loginName}})</template>
        </el-table-column> -->
        <el-table-column prop="amount" :label="$t('amount')" width="200">
          <template slot-scope="scope">
            <span class="franc">₣</span>{{scope.row.amount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="applyRemark" :label="$t('applayRemark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" :label="$t('checkStatus')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==2" class="text-primary">{{$t('checkPending')}}</span>
            <span v-if="scope.row.status==3" class="text-success">{{$t('getApproved')}}</span>
            <span v-if="scope.row.status==4" class="text-danger">{{$t('notApproved')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkRemark" :label="$t('checkRemark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('verifyTime')" width="200">
          <template slot-scope="scope">{{scope.row.checkTime?$manba(scope.row.checkTime).format('ymdhms'):''}}</template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="120">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==2" type="primary" size="mini" @click="openCheckApply(scope.row)">{{$t('check')}}</el-button>
            <el-button v-else size="mini" disabled>{{$t('check')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('cashAccountRechargeApply')" :visible.sync="drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="500">
      <el-form :model="checkForm" :rules="rules" ref="checkForm">
        <el-divider content-position="left">{{$t('applyInfo')}}</el-divider>
        <el-form-item :label="$t('applyAmount')">
          <el-input size="medium" v-model="applyForm.amount" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('applayRemark')">
          <el-input type="textarea" :rows="3" size="medium" v-model="applyForm.applyRemark" disabled></el-input>
        </el-form-item>

        <el-divider content-position="left">{{$t('checkInfo')}}</el-divider>
        <el-form-item :label="$t('status')" prop="status">
          <el-select v-model="checkForm.status">
            <el-option :value="3" :label="$t('getApproved')"></el-option>
            <el-option :value="4" :label="$t('notApproved')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('remark')">
          <el-input type="textarea" :rows="3" size="medium" v-model="checkForm.remark"></el-input>
        </el-form-item>

        <div class="bottom-button">
          <el-button size="medium" type="primary" :loading="isLoading" @click="onSubmit('checkForm')">{{$t('submit')}}</el-button>
        </div>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>
import card from '@/api/card'

export default {
  name: "cashAccountRechargeCheck",
  data () {
    return {
      queryTableHeight: String,
      queryForm: {},
      applyForm: {},
      checkForm: {},
      isLoading: false,
      tableData: {},
      pageSize: 20,
      currentPage: 1,
      drawer: false,
    }
  },
  computed: {
    rules () {
      return {
        status: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
  },
  methods: {
    getList () {
      let _this = this;
      _this.isLoading = true;
      card.pageOrgCashReceive({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isLoading = false;
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    openCheckApply (row) {
      this.drawer = true;
      this.applyForm = row;
      this.checkForm = {};
    },
    onSubmit (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isLoading = true;
        let p = { cashReceiveId: _this.applyForm.id, ..._this.checkForm }
        card.orgCashRechargeCheck({
          param: p,
          success: function (res) {
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'))
              _this.drawer = false;
              _this.getList();
            }
            else {
              _this.$message.warning(_this.$t(res.msg || 'operationFailure'))
            }
            _this.isLoading = false;
          }
        })
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
  },
  created () {
    this.getList();
  }
}
</script>

<style lang="less" scoped>
</style>